import React from "react";
import { graphql } from "gatsby";
import PageBuilder from "../components/page-builder";

export const query = graphql`
  query ($pageId: ID!) {
    fiveoakcms {
      landingpage(id: $pageId) {
        id
        title
        published_at
        includefooter
        headertype
        meta {
          ...PageMetaContent
          ...PageScriptContent
          ...PageRawjsContent
        }
        content {
          ...LandingHeroContent
          ...LandingLogosContent
          ...LandingContentimageContent
          ...LandingStepslistContent
          ...LandingVideofooterContent
          ...LandingSuccessstoriesContent
          ...LandingSocialproofContent
          ...LandingRawhtmlContent
          ...LandingHeronearmeContent
          ...LandingFinalctaContent
          ...LandingVideoContent
        }
      }
    }
  }
`;

const LandingPage = ({ data }) => {
  const landingpage = data.fiveoakcms.landingpage;

  return (
    <PageBuilder pageData={landingpage} />
  );
};

export default LandingPage;
